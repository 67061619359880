import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const ACADEMY_LOCALES = {
	WOLTAIR_CZ: 'academy',
	WOLTAIR_PL: 'akademia',
	WOLTAIR_DE: 'academy',
	WOLTAIR_IT: 'academy'
}

export const ACADEMY = ACADEMY_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return ACADEMY === param
}) satisfies ParamMatcher
